.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  // margin: 0 0 1px;
  padding: 25px 50px;
  box-shadow: 0 1px 0 0 #544e64;
  background-color: #2b3740;
  border-bottom: 1px solid #1c1532;

  &__link {
    margin-top: 1.5rem;
    width: 60%;
    justify-content: space-between;
    align-items: center;

    &__clicked {
      cursor: pointer;
      color: #fff;
      font-size: 15px;
      height: 70px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      border-bottom: 3px solid #688faa;
      font-family: poppins-bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 22px;
      letter-spacing: normal;
      display: flex;
      align-items: center;

      &:hover {
        color: white;
        font-weight: 600;
      }
    }

    &__unclicked {
      cursor: pointer;
      color: #a3a8ac;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 22px;
      height: 70px;
      // margin: 0 1rem 1rem 1rem;
      display: flex;
      align-items: center;
    }
  }
}

.nav-icon {
  width: 20px;
  height: 20px;
  margin-right: 1rem;
}

// NEW HEADER CSS
.navbar input[type="checkbox"],
.navbar .hamburger-lines {
  display: none;
}

.container {
  width: 100%;
  margin: auto;
}

.navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  box-shadow: 0 1px 0 0 #544e64;
  background-color: #2b3740;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  align-items: center;
  height: 70px;
}

.menu-items {
  order: 2;
  width: 100%;
  display: flex;
  margin-left: 5vw;
  justify-content: space-between;
  align-items: center;
  height: 70px;

  padding-right: 5rem;


  @media (max-width: 768px) {
    margin-left: 0px;

  }
  @media (max-width: 900px) {
    padding: 20px;
    align-items: normal;
    justify-content: normal;
  }
  .menus {
    display: flex;

    .Mui-selected {
      color: #fff;
      font-weight: 600;
    }

    .MuiTabs-indicator {
      background-color: #688faa;
      height: 4px;
      border-radius: 2px;
    }
    @media (max-width: 900px) {
      flex-direction: column;
    }
  }
}

.active-area {
  display: flex;
  
  @media (max-width: 1024px) {
    flex-direction: row;
  }
}

.logo {
  order: 1;
  margin-left: 2vw;
  font-size: 1.8rem;
  width: 70px;
  height: 70px;
  padding: 10px;

  @media (max-width: 1070px) {
    margin-right: -38px;
  }

  @media (max-width: 768px) {
      position: absolute;
      top: -15px;
      right: 15px;
      font-size: 1.7rem;
      width: 90px;
      height: 90px;
      margin-right: 0.1rem;
      padding: 20px 20px 20px 20px;
    }
}


.menu-items li,
button {
  list-style: none;
  //margin-left: 1.5rem;
  font-size: 1.1rem;
  //display: flex;
  align-items: center;
}

.navbar a {
  font-style: normal;
font-weight: 400;
line-height: normal;
font-size: 15px;

  text-decoration: none;
  transition: color 0.3s ease-in-out;
  margin-left: -0.8rem;
  margin-right: -0.8rem;
  @media (max-width: 1080px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}



.connect-button {
  background: #F83472;
  border: 1px solid #F83472;
  border-radius: 4px;
  width: 200px;
  height: 45px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  @media (max-width: 900px) {
    width: 90%;
    margin-bottom: 2rem;
    position: fixed;
    bottom: 0;
    right: 0px;
  }
}

button {
  //padding: 8px 19px;

  //background-color: rgba(0, 136, 169, 1);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all ease-in-out 0s;
}

button:hover a {
  color: #fff;
}

button:hover {
  //background: #f1789e;
  color: #fff;
}

button:active {
  background: #f83472;
}

.navbar a:hover {
  color: #fff;
  font-weight: 600;
  font-style: normal;

}

@media (max-width: 900px) {
  .navbar-container input[type="checkbox"],
  .navbar-container .hamburger-lines {
    display: block;
  }

  .navbar-container {
    display: block;
    position: relative;
    height: 64px;
  }

  .navbar-container input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .navbar-container .hamburger-lines {
    display: block;
    height: 28px;
    width: 35px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1024px) {
      justify-content: space-around;
    }
  }

  .navbar-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #fff;
  }

  .navbar-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-container .hamburger-lines .line2 {
    transition: transform 0.1s ease-in-out;
  }

  .navbar-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar .menu-items {
    padding-top: 54px;
    background: #1a2730;
    height: 100vh;
    width: 100%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
    overflow: scroll;

    @media (max-width: 1024px) {
      padding-top: 70px;
    }
  }

  .navbar .menu-items li {
    margin-bottom: 1.8rem;
    font-size: 1rem;
    font-weight: 500;
  }

  button {
    width: fit-content;
    margin-left: 0rem;
  }

  .logo {
    position: absolute;
    top: -15px;
    right: 15px;
    font-size: 1.7rem;

    width: 120px;
    height: 120px;
    // padding-top: 25px;

    padding: 15px 17px 15px 20px;
    @media (max-width: 768px) {
      position: absolute;
      top: -15px;
      right: 15px;
      font-size: 1.7rem;
      width: 90px;
      height: 90px;
      margin-right: 0.1rem;
      padding: 20px 20px 20px 20px;
    }
  }

  .navbar-container input[type="checkbox"]:checked~.menu-items {
    transform: translateX(0);
  }

  .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line3 {
    transform: rotate(-45deg);
  }
}

@media (max-width: 500px) {
  .navbar-container input[type="checkbox"]:checked~.logo {
    display: none;
  }
}

.detail-button {
  width: 100%;
  margin-bottom: 2rem;
}

.balance{
  color: rgb(163, 168, 172);
  font-size: 15px;
  // font-family: 'DM Sans', system-ui, sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: 0.8rem;
  margin-left: 0.5rem;
}

.modal_bignum{
  color: #bdbec1;
  font-size: 24px;
  // font-family: 'DM Sans', system-ui, sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.modal_smallnum{
  color: #A3A8AC;
  font-size: 17px;
  // font-family: 'DM Sans', system-ui, sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}




@keyframes blinkGreen {
  0%, 50% {
    background-color: transparent;
  }
  40%, 60% {
    background-color: #2C923C;
  }
}

@keyframes blinkBlue {
  0%, 50% {
    background-color: transparent;
  }
  40%, 60% {
    background-color: #77c4ff;
  }
}

.blinking-div {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  margin-right: 6px;
  animation: blinkGreen 2s infinite; /* Increased duration */
}

.blinking-div.blue {
  animation: blinkBlue 2s infinite; /* Increased duration */
}