.liquidity-item {
  margin: 2rem 0rem;
  padding: 0 0 2rem 0;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgba(225, 226, 230, 0.3);

  &:hover {
    cursor: pointer;
  }

  @include respond(tab-port) {
    flex-direction: column;
    margin: 0;
    padding: 2rem 2rem;
  }

  &__big-space {
    width: 40%;
    align-items: center;

    @include respond(tab-port) {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  &__small-space {
    width: 20%;
    justify-content: flex-end;
    @include respond(tab-port) {
      width: auto;
      margin-bottom: 2rem;
    }
  }

  &__image {
    // height: 27px;
    width: 27px;
    margin-right: 1rem;
  }

  &__title {
    color: #FFF;
    font-weight: 400;
    letter-spacing: 0;
    font-size: 15px;
    line-height: 21px;
  }

  &__expand {
    cursor: pointer;
  }

  &__value {
    color: #FFF;
    letter-spacing: 0;
    line-height: 25px;
    margin-right: 1rem;
    font-weight: 400;
    font-size: 15px;

    @include respond(tab-port) {
      width: 100%;
      margin-right: 1rem;
      font-weight: 200;
    }

    &-available {
      color: #FFF;
      letter-spacing: 0;
      line-height: 25px;
      margin-right: 3.5rem;
      cursor: pointer;
    }

    &-unavailable {
      color: rgba(225, 226, 230, 0.6);
      letter-spacing: 0;
      line-height: 25px;

      @include respond(tab-port) {
        margin-right: 3.5rem;
      }
    }
  }

  &__apy {
    color: #FF6E9C;
    letter-spacing: 0;
    line-height: normal;
    margin-right: 1rem;
    font-weight: 600;
    font-size: 15px;

    @include respond(tab-port) {
      width: 100%;
      margin-right: 1rem;
      font-weight: 600;
    }

    &-available {
      color: #FFF;
      letter-spacing: 0;
      line-height: 25px;
      margin-right: 3.5rem;
      cursor: pointer;
    }

    &-unavailable {
      color: rgba(225, 226, 230, 0.6);
      letter-spacing: 0;
      line-height: 25px;

      @include respond(tab-port) {
        margin-right: 3.5rem;
      }
    }
  }

  &__dashline {
    border-bottom: 1px dotted #727e8c;
    height: 2rem;
    width: 100%;
  }
}
