.nft-topic {
  padding-top: 1rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &__big-space {
    width: 57%;
    align-items: center;
    @include respond(tab-port) {
      width: 100%;
    }
  }

  &__small-space {
    // width: 15%;

    img {
      height: 40px;
      width: 40px;
    }
  }

  &__col-title {
    color: rgba(225, 226, 230, 0.6);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
  }
}
