.nft-launchpad-modal {
  width: 370px;
  height: 614px;
  background: #2b3740;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include respond(medium-desktop) {
    height: 550px;
  }

  @include respond(laptop) {
    height: 500px;
  }

  .choose-container {
    position: relative;
    border-bottom: 1px solid #54585b;
    padding-bottom: 3rem;
  }

  .balance-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .price {
    font-family: "poppins-bold";
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
    color: #ff6e9c;
  }

  .currency {
    font-family: "poppins-regular";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    color: #a3a8ac;
  }

  .unit {
    font-family: "poppins-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #ffffff;
  }

  .buy-button {
    background: #f83472;
    border-radius: 6px;
    font-size: 15px;
    width: 100%;
  }

  .redeem-button {
    background: rgba(255, 255, 255, 0.0001);
    border: 1px solid #54585b;
    font-size: 15px;
    border-radius: 6px;
    width: 100%;
  }
}

.divider {
  height: 1px;
  border-bottom: 0.5px solid #54585b;
  width: 100%
}