.clickable-input {
  width: 100%;
  margin-bottom: 2rem;
  background-color: #2b2c3e;
  justify-content: space-between;
  // background: transparent;
  // border: none;
  // padding: 0.5rem;
  color: #f7f7f7;
  // letter-spacing: 0;
  // line-height: 25px;
  // width: 40%;
  cursor: pointer;
  // padding: 0.5em;

  &::placeholder {
    color: rgba(225, 226, 230, 0.5);
    font-size: 15px;
    font-weight: 200;
  }

  &:focus {
    // outline: none;
    cursor: text;
    color: #333333;
    background-color: white;
    border-color: #333333;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    width: 100%;
  }

  // input[type='number'],
  // textarea {
  //   background-color: #2b2c3e;
  // }

  &__input__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__max-button {
    background-color: #65657a;
    border-radius: 4px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #f7f7f7;
    letter-spacing: 0;
    line-height: 21px;
    padding: 0.5rem;
    height: 3.5rem;
    cursor: pointer;
    color: #e1e2e6;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
  }

  &__arrow-buttons {
    justify-content: space-around;
    margin: 1rem;
    height: 2.5rem;
  }

  &__up-down-button {
    padding: 0.2rem;
    margin: 0.2rem;
    height: 15px;
    width: 15px;
    border-radius: 4px;
    font-size: 17px;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &__image {
      height: 17.33px;
      width: 22px;
    }
  }
}
