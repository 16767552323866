.unstaking-modal {
  height: 178px;
  width: 20%;
  border-radius: 10px;
  background-color: #2b2c3e;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  @include respond(medium-desktop) {
    width: 28%;
  }

  @include respond(laptop) {
    width: 45%;
  }

  @include respond(tab-land) {
    width: 58% 6rem;
  }

  @include respond(tab-port) {
    width: 77%;
  }

  &__header {
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    color: #f7f7f7;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    padding: 2rem 0 2rem 3rem;
    font-weight: 500;
  }

  &__close {
    color: #6e788a;
    font-size: 20px;
    letter-spacing: 0;
    margin: 1rem;
    cursor: pointer;
    height: 1.3rem;
    width: 1.3rem;
  }

  &__text {
    color: #e1e2e6;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    padding: 0 0 2rem 3rem;
  }

  &__buttons {
    width: 100%;
    justify-content: space-around;
    margin-bottom: 1rem;

    button {
      width: 40%;
    }
  }
}
