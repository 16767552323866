.launchpad-terms-modal {
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.launchpad-terms-text {
  // height: 600px;
  height: calc(100% - 12rem);
  overflow: scroll;
  ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.launchpad-terms-text::-webkit-scrollbar {
  display: none;
}

.launchpad-terms-subtitle {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin: 40px 6px 16px 0;
}

.launchpad-terms-detail {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a3a8ac;
  margin: 16px 0 32px;
}

.agree-text {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.buy-button {
  width: 100%;
  &__disable {
    width: 100%;
    cursor: not-allowed;
    opacity: 50%;
    background-color: #f83472;
    &:hover {
      background-color: #a3a8ac;
    }
  }
}

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: white;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background: rgba(0,0,0,0);
  border-radius: 5px;
  border: 1px solid #888;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  border: 1px solid #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
