.my-nfts {
  background: #1a2730;
  padding: 10rem 5rem 4rem 5rem;
  min-height: 100vh;
}

.nft-heading {
  margin: 2rem 0;
  font-size: 27px;
}

.nft-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  @include respond(medium-desktop){
    grid-template-columns: repeat(4, 1fr);
  }
  @include respond(laptop){
    grid-template-columns: repeat(3, 1fr);
  }
  @include respond(tab-land){
    grid-template-columns: repeat(3, 1fr);
  }
  @include respond(tab-port){
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(phone){
    grid-template-columns: repeat(1, 1fr);
  }
}


.emptyPageContainer {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.emptyImg {
  width: 150px;
}

.emptyText {
  display: flex;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 400;
  text-align: center;
  color: #a3a8ac;
}

.connect-button-mynft {
  background:rgba(234,70,119);
  border: 1px solid rgba(234,70,119);;
  border-radius: 6px;
  width: 250px;
  height: 50px;
  display: flex;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  margin-top: 40px;
  @media only screen and (max-width: 768px) {
    // width: 80%;
    margin-top: 5rem;
    bottom: 0;
  }
}
