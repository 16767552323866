@keyframes slidein {
  0% {
    background-color: blue;
    margin-top: 100%;
  }
  100% {
    background-color: black;
    margin-top: 20px;
  }
}

.pool-card {
  display: flex;
// width: 340px;
padding: 30px 20px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
align-content: flex-start;
// justify-content: space-between;

  height: auto;
  border-radius: 8px;
  background: var(--grey-dark, #2B3740);
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .pool-card {
    // width: 345px;

  }
  .stake-button {
    margin-right: 5px;
  }
}
.other-card {
  display: flex;
width: 370px;
padding: 24px 20px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
// gap: 24px;
  height: 400px;
  border-radius: 4px;
  background: var(--grey-dark, #2B3740);
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

.nft-details:hover .nft-details__hover {
  top: 260px;
  height: 140px;
}

// .nft-details__hover {
//   position: absolute;
//   top: 100%;
//   left: 0;
//   right: 0;
//   background-color: #11161a;
//   border-radius: 0 0 10px 10px;
//   overflow: hidden;
//   width: 100%;
//   height: 0;
//   transition: 0.5s ease;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-around;
// }

.card-hovered {
  position: absolute;

  height: 190px;
  width: 270px;
  // margin-top: 20px;
  margin-top: 100%;
  margin-left: -20px;
  background-color: transparent;
  border-radius: 0px 0px 10px 10px;
  transition: background-color 1s;
  animation: slidein 1s;
  animation-fill-mode: forwards;
}

.pool-image {
  width: 40px;
  height: 40px;
  border-radius: 1rem;
}

.pool-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  font-size: 15px;
  color: #ffffff;
  margin-left: 5px;
  padding-bottom: 5px;
}

.nft-token-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
}

.nft-contract-address {
  font-family: "poppins-regular";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #a3a8ac;
  overflow-wrap: anywhere;
}

.nft-divider {
  padding-top: 19px;
  height: 1px;
  border-bottom: 1px solid #54585b;
}

.nft-creator {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.nft-creator-box {
  display: flex;
  align-items: center;
}

.nft-creator-name {
  padding-left: 12px;
  color: white;
}

.nft-creator-image {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-rows {
  display: flex;
  flex-direction: column;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.info-title {
  color: var(--grey-light, #A3A8AC);
font-feature-settings: 'clig' off, 'liga' off;
/* Desktop/Footnote */
font-family: "poppins-regular";
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.info-value {
  color: var(--white, #FFF);
text-align: right;
font-feature-settings: 'clig' off, 'liga' off;
/* Desktop/body */
font-family: "poppins-regular";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.card-liquidity-item {
  border-bottom: 1px solid rgba(225, 226, 230, 0.3);

  
  &__section1 {
    padding: 20px 0;
    background-color: #4c565d;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    margin-bottom: 1rem;


  }
  &__rewards {
    width: 20%;
    justify-content: space-between;
    margin-right: 3rem;

    &__text-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }

  &__staking {
    width: 100%;
    justify-content: space-around;


    &__button {
      padding: 0.8rem 2.4rem;
      border-radius: 20px;
      background-color: #172136;
      border-color: #172136;

      &:hover {
        color: #f83472  
      }
    }

    &__text {
      width: 30%;
      justify-content: space-between;
      margin-right: 1rem;
    }

    &__buttons {
      width: 45%;
      justify-content: space-around;
      align-items: center;
      &:hover {
        color: #f83472  
      }

      &__a {
        margin-right: 2rem;
        padding: 2px;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
        border-radius: 6px;
        border: solid 1px rgba(255, 255, 255, 0.6);
        padding: 5px 20px;
        

        &:hover {
          color: #f83472  
        }
      }
    }

    &__text-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }

  &__title {
    color: rgba(225, 226, 230, 0.6);
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 1rem;
  }

  &__text {
    color: #e1e2e6;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 21px;
  }

  &__link {
    color: #ff6e9c;
    font-weight: 500;
    font-size: 12px;
    overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; 
          line-clamp: 1; 
  -webkit-box-orient: vertical;

    letter-spacing: 0;
    line-height: 18px;
    align-self: center;
    margin-right: 5px;
    &:hover {
      color: #3986eb;
    }

  }

  &__percentage-container {
    position: relative;
    display: inline-block;
  }

  &__percentage {
    background-color: #7b8287;
    height: 21px;
    width: 55px;
    border-radius: 20px;
    color: #FFF;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0;
    padding: 2px 5px ;
    line-height: 21px;
    text-align: center;
    
    &:hover {
      background-color: #f83472;
      color: #FFF;
    }
    &:hover + &__bubble {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
    }

    &__bubble {
      font-family: sans-serif;
      width: 150px;
      border-radius: 6px;
      background-color: #172136;
      padding: 6px;
      text-align: center;
      font-size: 15px;
      position: absolute;
      bottom: 40px;
      left: 0;
      color: #e1e2e6;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
      z-index: 10;
      opacity: 0;
      transition: opacity 1s;
    }

    &__bubble:before {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 12px solid #172136;
      border-right: 6px solid transparent;
      border-top: 6px solid #172136;
      border-bottom: 10px solid transparent;
      left: 32px;
      bottom: -15px;
    }

    &__silver{
      background-color: #6E7184;
      padding: 0px 8px 2px 8px;
      border-radius: 3px;
    }

    &__gold{
      background-color: #e3ab2b;
      padding: 0px 8px 2px 8px;
      border-radius: 3px;
    }

    &__bronze{
      background-color: #C78366;
      padding: 0px 8px 2px 8px;
      border-radius: 3px;
    }
  }

  &__first-rewardText {
    color: #FFF;
    padding: 0px 11px 0px 11px;
    align-items: center;
    &__unitText {
      font-size: 15px;
      margin: 2px 0 0 4px;
    }
  }

  &__rewardText {
    color: #FFF;
    margin-left: 10px;
    padding: 0px 11px 0px 11px;
    border-left: 2px solid rgba(255, 255, 255, 0.4);
    align-items: center;
    &__unitText {
      font-size: 13px;
      margin: 2px 0 0 4px;
    }
  }

  &__poolText {
    color: #FFF;
    padding-left: 11px;
    align-items: center;
    &__unitText {
      margin: 2px 0 0 4px;
    }
  }
  &__editionMark {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
  
  &__buttonGroup {
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  &__rowSpace {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

  }  
}

.reward-component {
  background: var(--background, #1A2730);
  padding: 16px;
  border-radius: 8px;
  color: white;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  max-height: 80px;
}

.reward-component.expanded {
  max-height: 200px;
}

.reward-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}

.reward-title {
  font-size: 18px;
  font-weight: bold;
}

.reward-icon {
  cursor: pointer;
}

.reward-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.reward-value {
  font-size: 24px;
  margin-bottom: 8px;
}

.reward-button {
  background-color: #ffcc00;
  color: #1a2730;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
