.nft-launchpad-page {
  filter: blur(2px);
  background-color: #1a2730;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  background-size: cover;
  
  .token-name {
    font-family: "poppins-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #a3a8ac;
  }

  .product-name {
    font-family: "poppins-bold";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    color: #ffffff;
  }
}

.nft-launchpad {
  position: absolute;
  right: 10%;
  top: 62%;
  transform: translate(0%, -60%);

  @include respond(phone) {
    position: relative;
    right: auto;
    top: auto;
    transform: none;
    align-items: center;
    margin-top: 5rem;
  }

  &__title {
    display: none;

    @include respond(phone) {
      display: block;
    }
  }

  .token-name {
    font-family: "poppins-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #a3a8ac;
  }

  .product-name {
    font-family: "poppins-bold";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
  }

  &__slider {
    width: 95%;
    position: absolute;
    left: -15%;
    top: 45%;
    transform: translate(0%, -40%);

    @include respond(phone) {
      width: 100%;
      position: relative;
      left: auto;
      top: auto;
      transform: none;
      padding: 10rem 2rem 2rem 2rem;
    }
  }
}

.launchpadloader {
  position: fixed;
  top: 70px;
  height: 100%;
  width: 100%;
  // -webkit-backdrop-filter: blur(2px);
  // backdrop-filter: blur(2px);
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.launchpadloadContainer {
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background-color: rgba(26, 39, 48, 1);
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}