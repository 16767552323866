.v_divider {
  border-left: 2px solid gray;
  margin-left: 1rem;
  margin-right: 1rem;
  height: 2rem;
}

.statusIcon {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}