@keyframes slidein {
  0% {
    background-color: blue;
    margin-top: 100%;
  }
  100% {
    background-color: black;
    margin-top: 20px;
  }
}

.nft-card {
  width: 270px;
  height: 400px;
  padding: 20px;
  background: #2b3740;
  border-radius: 10px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

.other-card {
  width: 230px;
  height: 400px;
  padding: 20px;
  background: #2b3740;
  border-radius: 10px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

.nft-details:hover .nft-details__hover {
  top: 260px;
  height: 140px;
}

.nft-details__hover {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #11161a;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.card-hovered {
  position: absolute;

  height: 190px;
  width: 270px;
  // margin-top: 20px;
  margin-top: 100%;
  margin-left: -20px;
  background-color: transparent;
  border-radius: 0px 0px 10px 10px;
  transition: background-color 1s;
  animation: slidein 1s;
  animation-fill-mode: forwards;
}

.nft-image {
  width: 230px;
  height: 230px;
  border-radius: 1rem;
}

.nft-title {
  font-family: "poppins-bold";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 32px;
  color: #ffffff;
  padding-top: 15px;
  padding-bottom: 15px;
}

.nft-token-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
}

.nft-contract-address {
  font-family: "poppins-regular";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #a3a8ac;
  overflow-wrap: anywhere;
}

.nft-divider {
  padding-top: 19px;
  height: 1px;
  border-bottom: 1px solid #54585b;
}

.nft-creator {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.nft-creator-box {
  display: flex;
  align-items: center;
}

.nft-creator-name {
  padding-left: 12px;
  color: white;
}

.nft-creator-image {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
