.nft-detail {
  background: #1a2730;
  padding: 10rem 5rem 4rem 5rem;
  min-height: 100vh;
  align-items: center;

  &__back {
    align-items: center;

    &:hover {
      cursor: pointer;
    }
    &__text {
      color: #fff;
      padding-left: 15px;
    }
  }

  &__slider-text {
    position: absolute;
    top: 3.5rem;
    color: #9fcfe7;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }

  &__otherNft {
    justify-content: center;
    margin-top: 6rem;
    &__nft-container {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
      @include respond(medium-desktop) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include respond(laptop) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include respond(tab-land) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include respond(tab-port) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  &__section {
    margin-top: 28px;
    display: flex;

    @include respond(laptop) {
      flex-direction: column;
    }
    &__image {
      width: 100%;
      min-height: 400px;
      border-radius: 10px;
    }
    &__info {
      margin-left: 40px;
      @include respond(laptop) {
        margin-left: 0px;
        margin-top: 2rem;
      }
      width: 100%;

      &__main {
        padding: 40px 39px 26px 40px;
        background: #2b3740;
        border-radius: 10px;
      }

      &__details {
        margin-top: 40px;

        &__body {
          &__props {
            margin-top: 20px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
          }
          &__prop {
            padding: 20px;
            background: #4c565d;
            border-radius: 6px;
          }
          &__boost {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            @include respond(tab-land) {
              grid-template-columns: repeat(1, 1fr);
            }
            gap: 20px;
          }
        }
      }
      &__unique {
        padding: 7px 0;
        &__title {
          color: #a3a8ac;
          white-space: nowrap;
          padding-right: 7px;
        }
        &__idcontainter {
        }

        &__value {
          padding-left: 7px;
        }
      }

      &__edition {
        margin-top: 25px;
        margin-bottom: 10px;
        justify-content: space-between;
        align-items: center;

        &__image {
          width: 20px;
          height: 20px;
          border-radius: 10px;
        }

        &__name {
          color: #a3a8ac;
          padding-left: 12px;
          font-size: 13px;
        }
      }

      &__price {
        margin-top: 10px;
        align-items: baseline;
      }
    }

    &__intro {
      color: white;
      margin-bottom: 24px;
    }
  }

  &__bigtext {
    color: #fff;
  }

  &__smalltext {
    color: #a3a8ac;
    line-height: 1.38;
    margin-top: 3px;
    margin-bottom: 23px;
  }
}

.response {
  width: 1280px;
  @include respond(medium-desktop) {
    width: 1024px;
  }
  @include respond(laptop) {
    width: 800px;
  }
  @include respond(tab-land) {
    width: 600px;
  }
  @include respond(tab-port) {
    width: 480px;
  }
  @include respond(phone) {
    width: 380px;
  }
}

.chartContainer {
  width: 50%;
}

.mySwiper {
  width: 1280px;
  @include respond(medium-desktop) {
    width: 1024px;
  }
  @include respond(laptop) {
    width: 800px;
  }
  @include respond(tab-land) {
    width: 600px;
  }
  @include respond(tab-port) {
    width: 480px;
  }
  @include respond(phone) {
    width: 380px;
  }
  overflow: hidden;
}


.swiper-slide {
  text-align: center;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}