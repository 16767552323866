
  .fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 160px; 
    height: 60px;
    background-color: #ff4081; /* or any color you prefer */
    border-radius: 100px;
    text-align: center;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s;
    display: flex;        /* Use flex layout */
    align-items: center;  /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    overflow: hidden;     /* Hide overflow (initially hides the text) */
    padding: 0 20px;      /* Padding for aesthetic spacing */
    transition: width 0.3s ease, background-color 0.3s; /* Transition for width and background-color */
}

/* .fab:hover {
    background-color: #e53567;
    width: 160px; 
    margin-right: 10px;
} */

.fab i {
  line-height: 60px;
  font-size: 24px;
  color: white;
  margin-right: -83px;
  transition: width 0.3s ease, background-color 0.3s; /* Transition for width and background-color */
}


.fab i {
  margin-right: 10px;
}


.fab-text {
  color: white;
  font-size: 15px;
  white-space: nowrap; /* Prevents text wrapping */
  opacity: 1;          /* Initially hide text */
}

.fab .fab-text {
    opacity: 1; /* Show text on hover */
}