.input {
  background: transparent;
  border: none;
  padding: 0.5rem;
  color: #f7f7f7;
  letter-spacing: 0;
  line-height: 25px;
  width: 40%;
  cursor: pointer;
  padding: 0.5em;
  
  &::placeholder {
    color: rgba(225, 226, 230, 0.5);
    font-size: 15px;
    font-weight: 200;
  }

  &:focus {
    outline: none;
    cursor: text;
    color: #333333;
    background-color: white;
    border-color: #333333;
  }
}
