.wallet-modal {
  // height: 390px;
  width: 490px;
  padding: 25px 10px;
  border-radius: 4px;
  background-color: #2B3740;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-around;
  align-items: center;
  padding: 15px 20px;
  z-index: 10001;

  @media (max-width: 500px) {
    width: 340px;
    padding: 0px 30px 10px;
  }

  &__header {
    // justify-content: flex-end;
    padding: 5px;
  }

  &__close {
    margin: 0.5rem;
    height: 1.3rem;
    width: 1.3rem;
    cursor: pointer;
  }

  &__title {
    color: #e1e2e6;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    font-weight: 600;
    margin: 2rem;
  }

  &__text {
    height: 20px;
    width: 345px;
    color: #e1e2e6;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 2rem;
  }
}

.wallet-column {
  .wallet-button {
    
    align-items: flex-start;
    justify-content: center;
    // width: 80%;
    display: flex;
    justify-content: center;
    // margin: 1.5rem 0;
    // background: #fff;
    color: #1c1532;
    border:1.5px solid #4C565D;
    // cursor: pointer;
  }

  .wallet-img {
    width: 4.3rem;
    // height: 4.3rem;
    // margin-right: 1rem;
    // margin-top: 5px;
    padding: 1px;
  }
}

.wallet-modal__nowallet {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  height: 30%; /* Set a specific height for the Row, adjust as needed */
}

.grid-container {
  display: grid;
  gap: 20px 20px;
  grid-template-columns: auto auto auto;

  @include respond(tab-land){
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 2px;
  }
  @include respond(tab-port){
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 2px;

  }
  @include respond(phone){
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 2px;

  }
  // background-color: #2196F3;
  // padding: 10px;
  padding: 20px;
  margin-bottom: 0px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #4C565D;
  padding: 20px;
  font-size: 30px;
  // width: 30%;
  text-align: center;
}


.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #f83472;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  // left: 9px;
   top: 5px;
  width: 5px;
  height: 10px;
  // border: solid white;
  // border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.react-responsive-modal-closeButton {
  border-radius: 6px;
  background-color: gray;
}

.react-responsive-modal-closeButton > svg{
  fill: #fff;
  padding: 2px;
}