// fonts
@import "./fonts/fonts";

// abstracts
@import "./abstracts/variables";
@import "./abstracts/mixins";

// base
@import "./base/base";
@import "./base/typography";
@import "./base/animations";

// pages
@import "./pages/pages";

// common components
@import "./components/components";

//
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
