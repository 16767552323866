.expanded-liquidity-item {
  border-bottom: 1px solid rgba(225, 226, 230, 0.3);

  &__section1 {
    padding: 20px 0;
    background-color: #4c565d;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    margin-bottom: 1rem;

    @include respond(phone) {
      background-color: #2b3740;
    }
  }
  &__rewards {
    width: 20%;
    justify-content: space-between;
    margin-right: 3rem;

    &__text-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }

  &__staking {
    width: 100%;
    justify-content: space-around;

    @include respond(medium-desktop) {
      margin-top: 1rem;
      margin-bottom: 1rem;
      justify-content: space-evenly;
    }

    &__button {
      padding: 0.8rem 2.4rem;
      border-radius: 20px;
      background-color: #172136;
      border-color: #172136;

      @include respond(laptop) {
        padding: 0.8rem 2.2rem;
      }
      @include respond(tab-land) {
        padding: 0.6rem 2rem;
      }
      &:hover {
        color: #f83472  
      }
    }

    &__text {
      width: 30%;
      justify-content: space-between;
      margin-right: 1rem;
    }

    &__buttons {
      width: 45%;
      justify-content: space-around;
      align-items: center;
      &:hover {
        color: #f83472  
      }

      &__a {
        margin-right: 2rem;
        padding: 2px;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
        border-radius: 6px;
        border: solid 1px rgba(255, 255, 255, 0.6);
        padding: 5px 20px;
        
        @include respond(phone) {
          margin-right: 0;
          display: flex;
          width: 100%;
          justify-content: center;
          padding: 15px;
        }
        &:hover {
          color: #f83472  
        }
      }
    }

    &__text-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }

  &__title {
    color: rgba(225, 226, 230, 0.6);
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 1rem;
  }

  &__text {
    color: #e1e2e6;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 21px;
  }

  &__link {
    color: #ff6e9c;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    align-self: center;
    margin-right: 5px;
    &:hover {
      color: #3986eb;
    }

    @include respond(phone) {
      margin: 1.5rem 0rem 1rem 0;
    }
  }

  &__percentage-container {
    position: relative;
    display: inline-block;
  }

  &__percentage {
    background-color: #7b8287;
    height: 21px;
    width: 55px;
    border-radius: 20px;
    color: #FFF;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    
    &:hover {
      background-color: #f83472;
      color: #FFF;
    }
    &:hover + &__bubble {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
    }

    &__bubble {
      font-family: sans-serif;
      width: 150px;
      border-radius: 6px;
      background-color: #172136;
      padding: 6px;
      text-align: center;
      font-size: 15px;
      position: absolute;
      bottom: 40px;
      left: 0;
      color: #e1e2e6;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
      z-index: 10;
      opacity: 0;
      transition: opacity 1s;
    }

    &__bubble:before {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 12px solid #172136;
      border-right: 6px solid transparent;
      border-top: 6px solid #172136;
      border-bottom: 10px solid transparent;
      left: 32px;
      bottom: -15px;
    }
  }

  &__first-rewardText {
    color: #FFF;
    padding: 0px 11px 0px 11px;
    align-items: center;
    &__unitText {
      font-size: 15px;
      margin: 2px 0 0 4px;
    }
  }

  &__rewardText {
    color: #FFF;
    margin-left: 10px;
    padding: 0px 11px 0px 11px;
    border-left: 2px solid rgba(255, 255, 255, 0.4);
    align-items: center;
    &__unitText {
      font-size: 13px;
      margin: 2px 0 0 4px;
    }
  }

  &__poolText {
    color: #FFF;
    padding-left: 11px;
    align-items: center;
    &__unitText {
      margin: 2px 0 0 4px;
    }
  }
  &__editionMark {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
  
  &__buttonGroup {
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  &__rowSpace {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    @include respond(phone) {
      margin-bottom: 1.5rem;
    }
  }  
}

.dashline {
  border-bottom: 1px dotted #727e8c;
  height: 2rem;
  width: 100%;
}

.stake-button:hover {
  color: #f83472 !important; // Change to the desired hover background color
}

.stake-button {
  color: #fff;
  background: #f6f5f600 !important;
  border: solid 1px rgba(255, 255, 255, 0.6);
  margin-right: 2rem;
  width: 120px;
  transition: background-color 0.3s ease; // Optional: Add a smooth transition effect
}
