@mixin flex($dir: row, $jc: center, $ai: center) {
  display: flex;
  flex-direction: $dir;
  justify-content: $jc;
  align-items: $ai;
}

@mixin boxShadow {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } // 600px
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    } // 900px
  }
  @if $breakpoint == tab-land {
    @media (max-width: 65em) {
      @content;
    }
  }
  @if $breakpoint == laptop {
    @media (max-width: 75em) {
      @content;
    } // 1200px
  }
  @if $breakpoint == medium-desktop {
    @media (max-width: 95em) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } // 1800px
  }
}
