.launchpad-shipping-modal {
    align-items: center;
  
    @include respond(phone) {
      align-items: left;
    }
  }
  .redeem-detail-container {
    width: 100%;
    font-size: 15px;
    white-space: nowrap;
    margin-bottom: 1rem;
  }
  