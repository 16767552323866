.h1 {
}

.h3 {
  color: #f7f7f7;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

.h6 {
}
