@keyframes loadImage {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
}

.image-wrapper {
  position: relative;
  //   width: 100%;
  //   height: 50vw;
}

.image-placeholder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: loadImage .2s infinite;
}

.image {
  left: 0;
  // width: 100%;
  // height: 100%;
  object-fit: cover;
}
