.slide {
  transform: scale(0.7);
  transition: transform 300ms;
  opacity: 0.5;
}

.slideWrapper {
  display: flex;
  justify-content: center;
}

.slideImage {
  @include respond(medium-desktop) {
    width: 70%;
  }

  @include respond(laptop) {
    width: 55%;
  }

  @include respond(tab-land) {
    width: 58% 6rem;
  }

  @include respond(tab-port) {
    width: 70%;
  }
  @include respond(phone) {
    width: 40%;
  }
}
.arrow-btn {
  width: 57px;
  height: 57px;
  z-index: 100;
  cursor: pointer;
  padding: 0px;
  background-color: transparent;

  &:hover {
    background-color: grey;
  }
}

.beer-image {
  margin-top: -100px;
  margin-bottom: -100px;
  width: 300px;
  height: 800px;

  @include respond(medium-desktop) {
    height: 600px;
    margin-top: -100px;
  }
}

.indicate {
  color: #a3a8ac;
  font-size: 15px;
  text-align: center;
  align-items: center;
  display: flex;
  padding: 0 2rem;
}
