.toast-buttons {
  display: flex;
}

.toast-buttons button {
  color: white;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  height: 50px;
  margin: 0 10px;
  border: none;
  outline: none;
}

.select {
  display: flex;
  width: 30%;
  margin-top: 10px;
}

.position-select {
  background-color: inherit;
  color: #fff;
  width: 100%;
  height: 30px;
  font-size: 16px;
}

.success {
  background-color: rgba(75, 163, 207, 0.3);
}

.error {
  background-color: rgba(248, 52, 114, 0.3);
}

.info {
  background-color: #5bc0de;
}

.warning {
  background-color: #f0ad4e;
}

.notification-container {
  font-size: 14px;
  position: fixed;
  z-index: 999999999999;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.top-right {
  top: 30px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.notification {
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  margin-bottom: 15px;
  opacity: 0.9;
  background-repeat: no-repeat;
}

.notification:hover {
  box-shadow: 0 0 12px #fff;
  opacity: 1;
  cursor: pointer;
}

.notification-title {
  color: #fff;
  font-size: 17px;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 6px;
}

.notification-message {
  margin: 0;
  text-align: left;
  // height: 18px;
  color: rgba(255, 255, 255, 0.7);
  font-family: poppins-regular;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;
}

.notification-image {
  float: left;
  margin-right: 15px;
  margin-top: 0.2rem;
}

.notification-image img {
  height: 25px;
  width: 25px;
}

.toast {
  width: 335px;
  border-radius: 10px;
  color: #fff;
  padding: 25px 20px 30px 20px;
}

.notification-close-icon {
  position: relative;
  right: -1em;
  top: -1.3em;
  float: right;
  font-weight: 700;
  color: #fff;
  outline: none;
  border: none;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.8;
  line-height: 1;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
