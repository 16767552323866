.info-section {
  width: 30%;
  margin-top: 5.5rem;
  // overflow-y: scroll;
  @include respond(tab-port) {
    width: 100%;
    padding: 2rem;
  }

  @include respond(phone) {
    padding: 2rem;
    overflow-y: scroll;
    margin-top: 0.5rem;
  }

  &__detail {
    color: #FFF;
    margin: 3rem 0rem 2rem 0rem;
  }

  &__info-box-locked {
    padding: 2rem;
    height: 175px;
    width: 100%;
    margin-bottom: 1rem;

    @include respond(tab-port) {
      width: 100%;
    }
  }

  &__infoIcon {
    background-color: #4c565d;
    border-radius: 50%;
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }

  &__info-box-rewards {
    padding: 2rem;
    height: 300px;
    width: 100%;
    margin: 1rem 0rem;

    @include respond(tab-port) {
      width: 100%;
    }
  }

  &__info-box-rewards-no-height {
    padding: 2rem;
    width: 100%;
    margin: 1rem 0rem;

    @include respond(tab-port) {
      width: 100%;
    }
  }

  &__info-box-user {
    padding: 2rem;
    height: 260px;
    width: 100%;
    margin: 1rem 0rem;

    @include respond(tab-port) {
      width: 100%;
    }
  }

  &__header {
    color: #b3acbb;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
      color: #b3acbb;
      letter-spacing: 0;
      line-height: 25px;
      height: 18px;
      width: 18px;
    }
  }

  &__pair {
    align-items: flex-end;
    margin-top: 1rem
  }

  &__number {
    color: #e1e2e6;
    color: #e1e2e6;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 35px;
    margin-right: 0.5rem;
  }

  &__balance {
    color: #e1e2e6;
    color: #e1e2e6;
    font-size: 20px;
    // font-family: 'DM Sans', system-ui, sans-serif;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 25px;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  &__currency {
    color: #e1e2e6;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 0.3rem;
  }

  &__account {
    color: #b3acbb;
    letter-spacing: 0;
    line-height: 21px;
    word-wrap: break-word;
  }
}

.sticky-column {
  position: sticky;
  // padding-top: 50px;
  top: 80px; /* Adjust the value as needed */
  @include respond(phone) {
    top: 50px;
  }
  
}

.scroll-container {
    width: 30%;

  height: 100vh; /* Set the desired height for the scrollable area */
  overflow-y: scroll;
}

.expandable-box {
  position: relative;
}

.show-more-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: #787878;
  padding: 0;
  margin: 5px;
  padding: 7px;
  color: #c3c3c3c0; /* Choose the color you want for the button icon */
  font-size: 14px;
  transition-duration: 0.2s;
  transition-property: transform;
  cursor: pointer;
  outline: none;
}

.show-more-button.expanded {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
