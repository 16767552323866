.liqudity-topic {
  margin-bottom: 2rem;
  padding: 2rem 0 0 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &__big-space {
    width: 40%;
    align-items: center;
  }

  &__small-space {
    // width: 20%;

    img {
      height: 40px;
      width: 40px;
    }
  }

  &__col-title {
    color: var(--grey-light, #A3A8AC);

    color: rgba(225, 226, 230, 0.6);
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    font-size: 12px;
font-style: normal;
font-weight: 500;
  }
}
