.earnloader {
  position: fixed;
  top: 70px;
  height: 100%;
  width: 100%;
  // -webkit-backdrop-filter: blur(2px);
  // backdrop-filter: blur(2px);
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.earnloadContainer {
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background-color: rgba(26, 39, 48, 1);
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
