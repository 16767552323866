*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // 1rem = 10px
  scroll-behavior: smooth;
  height: 100%;
  margin: 0;
  background-color: #1a2730;
}

body {
  box-sizing: border-box;
  // font-family: 'DM Sans', system-ui, sans-serif;
  // font-weight: 400;
  // line-height: 1.7;
  // color: $base-text-color;
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}
