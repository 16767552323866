.nft-item {
  margin: 1rem 0rem;
  padding: 0 0 2rem 0rem;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgba(225, 226, 230, 0.3);

  &:hover {
    cursor: pointer;
  }

  @include respond(tab-port) {
    padding: 2rem 0;
    flex-direction: column;
  }

  &__big-space {
    width: 55%;
    align-items: center;

    @include respond(tab-port) {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  &__mid-space {
    width: 36%;
    align-items: center;
border-radius: 4px;
    @include respond(tab-port) {
      //width: 100%;
      margin-bottom: 2rem;
    }
  }

  &__small-space {
    width: 15%;
    justify-content: flex-end;
    white-space: nowrap;
    @include respond(tab-port) {
      width: auto;
      margin-bottom: 2rem;
    }
  }

  &__image {
    height: 124px;
    width: 124px;
    border-radius: 3px;
  }

  &__title {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    padding: 0 1.5rem;
  }

  &__value {
    color: #fff;
    letter-spacing: 0;
    line-height: 25px;
    margin-right: 3.5rem;
    font-weight: 300;

    @include respond(tab-port) {
      width: 100%;
      margin-right: 3.5rem;
      font-weight: 200;
    }

    &-available {
      color: #e1e2e6;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
      margin-right: 3.5rem;
      cursor: pointer;
    }

    &__mobile {
      color: rgba(225, 226, 230, 0.6);
      font-size: 18;
      font-weight: 500;
      margin-right: 1rem;
    }
  }

  &__apy {
    color: #FF6E9C;
    letter-spacing: 0;
    line-height: normal;
    margin-right: 1rem;
    font-weight: 600;
    font-size: 15px;

    @include respond(tab-port) {
      width: 100%;
      margin-right: 1rem;
      font-weight: 600;
    }

    &-available {
      color: #FFF;
      letter-spacing: 0;
      line-height: 25px;
      margin-right: 3.5rem;
      cursor: pointer;
    }

    &-unavailable {
      color: rgba(225, 226, 230, 0.6);
      letter-spacing: 0;
      line-height: 25px;

      @include respond(tab-port) {
        margin-right: 3.5rem;
      }
    }
  }
  &__value-unavailable {
    color: rgba(225, 226, 230, 0.6);
    letter-spacing: 0;
    line-height: 25px;

    @include respond(tab-port) {
      margin-right: 3.5rem;
    }
  }

  &__apy-staked {
    width: 100%;
    justify-content: space-between;
  }
}
