.farms {
  max-width: 1200px;
  margin: 0 auto;
  // padding: 0 15px;
  min-height: 100vh;
  background: #1a2730;
  padding: 10rem 15px 4rem 15px;
    @include respond(phone) {
    padding: 7rem 0rem 1rem 0rem;
  }

  a {
    text-decoration: none;
  }

  &__menu {
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 15px;

    @include respond(tab-port) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-left {
      width: 70%;
      margin-right: 3rem;

      @include respond(tab-port) {
        margin-right: 0rem;
        order: 2;
        width: 100%;
      }

      &__search-collectible {
        margin-bottom: 1.5rem;
        justify-content: space-between;
        align-items: center;

        &__title {
          color: #ffffff;
          font-size: 27px;
          font-family: "poppins-bold";
          font-weight: 600;
          letter-spacing: 0;
          line-height: 40.5px;
        }
      }
    }
  }
}

.search {
  position: relative;
  padding: 8px 16px 9px 20px;
  width: 83%;
  color: #fff;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: normal;
  border: solid 1px rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  background-color: #1a2730;
  transition: width 0.4s ease;
  outline: none;
  
  &:focus{ 
    
    width: 89%;

  }

  &__icon {
    top:50%;
    position: absolute;
    transform: translate( 0, -50%);
    right: 12rem;
  }
  @include respond(phone) {
    width: 100%;
  }
}

.view {
  position: relative;
  padding: 11px 16px 9px 20px;
  width: 10%;
  color: #fff;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: normal;
  border: solid 1px rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  background-color: #1a2730;
  transition: width 0.4s ease;
  outline: none;
  
  &:focus{ width: 100%; }

  &__icon {
    
    margin-left: 15px;
  }

  @include respond(phone) {
    width: 100%;
  }
}
.oval {
  width: 530px;
  height: 720px;
  position: absolute;
  left: 47.5%;
  right: -16.25%;
  top: -20.27%;
  bottom: 54.57%;

  background: #4BA3CF;
  mix-blend-mode: normal;
  opacity: 0.16;
  filter: blur(135.914px);
}

.farms-container {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  height: 40px;
}

.dropdown {
  position: relative;
  height: 80px;
  width: 226px;
  border: 1px solid #3988ef;
  border-radius: 8px;
  background-color: #2b2c3e;
  cursor: pointer;
  color: rgba(247, 247, 247, 0.6);
  // font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0;
  // line-height: 16px;
  text-align: center;
}

#drop {
  height: 121px;
  width: 226px;
  border-radius: 8px;
  background-color: #2b2c3e;
  visibility: hidden;
  opacity: 0;
  transform: translateY(0);
  transform-origin: 0% 0%;
  transition: 0.3s;
  display: grid;
  align-items: center;
}

#drop li {
  list-style: none;
  transition: all 0.3s;
  width: 70%;
  margin: auto;
  text-align: center;
  padding: 0.5rem 0;
}

#drop li:hover {
  transform: translateX(10px);
}

#drop li div {
  opacity: 0.8;
  color: #e1e2e6;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-decoration: none;
  cursor: pointer;
}

#drop li:hover div {
  color: #456ead;
}

#menu {
  display: none;
}

#menu:checked ~ ul {
  visibility: visible;
  opacity: 1;
  transform: translateY(1rem);
  transition: 0.3s;
}


.sl-spinner3 {
  width: 100px !important;
  height: 40px !important;
}


.pool-container {
  display: grid;
  margin-top: 3rem;
  grid-template-columns: repeat(2, 1fr);
  gap:  24px 24px;

  @include respond(medium-desktop){
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(laptop){
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(tab-land){
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(tab-port){
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(phone){
    grid-template-columns: repeat(1, 1fr);
  }
}


.pool-container-3 {
  display: grid;
  margin-top: 3rem;
  grid-template-columns: repeat(3, 1fr);
  gap:  24px 24px;

  @include respond(medium-desktop){
    grid-template-columns: repeat(3, 1fr);
  }
  @include respond(laptop){
    grid-template-columns: repeat(3, 1fr);
  }
  @include respond(tab-land){
    grid-template-columns: repeat(3, 1fr);
  }
  @include respond(tab-port){
    grid-template-columns: repeat(3, 1fr);
  }
  @include respond(phone){
    grid-template-columns: repeat(3, 1fr);
  }
}
.image-text-row {
  display: flex;
  align-items: center;
  // margin-bottom: 16px;
}

.item-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.item-details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5px;
  flex-wrap: nowrap;
}

.item-text {
  margin-bottom: 8px;
}

.main-text {
  color: var(--grey-light, #A3A8AC);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "poppins-regular", sans-serif;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.sub-text {
  color: #fff;
  letter-spacing: 0;
  line-height: normal;
  font-weight: 600;
  font-size: 20px;
}

.sub-text-apy {
  color: var(--hightlighted-text, #FF6E9C);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

  letter-spacing: 0;
  font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.details-space {
  width: 4rem; /* Adjust as needed */
}

.details-space-small {
  width: 5rem; /* Adjust as needed */
}