.staking-modal {
  border-radius: 10px;
  align-items: center;
  padding: 30px;
  width: 480px;
  background-color: #2b3740;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;

  @include respond(phone) {
    border-radius: 0px;
    width: 100%;
    position: relative;
    left: auto;
    top: auto;
    transform: none;
    height: 100vh;
  }

  &__header {
    width: 100%;
  }

  &__title {
    color: #f7f7f7;
    font-size: 27px;
    letter-spacing: 0;
    line-height: normal;
    font-weight: 600;
    padding-bottom: 10px;
  }

  &__slider-handle {
    &:focus {
      outline: none;
    }
  }

  &__sub-title {
    color: #a3a8ac;
    font-size: 15px;
    line-height: normal;
  }

  &__close {
    color: #6e788a;
    font-size: 20px;
    letter-spacing: 0;
    margin: 1rem;
    cursor: pointer;
    height: 1.3rem;
    width: 1.3rem;
  }

  &__balance {
    width: 100%;
    justify-content: space-between;
    padding: 1.5rem;
    margin: 30px 0;
    background-color: #4c565d;
    border-radius: 6px;
  }

  &__slider-text {
    position: absolute;
    top: 5.5rem;
    color: #9fcfe7;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }

  &__input__container {
    width: 100%;
    border-radius: 7px;
    border: solid 1px #54585b;
    margin-bottom: 2rem;
    background-color: #1a2730;
    padding: 0.7rem 1rem;
    justify-content: space-between;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"].input::placeholder {
    color: rgba(225, 226, 230, 0.5);
    font-size: 15px;
    font-weight: 200;
  }

  input[type="number"].input:focus {
    outline: none;
    cursor: text;
    color: #fff;
    background-color: transparent;
    border-color: #333333;
  }

  /* Firefox */
  input[type="number"].input {
    -moz-appearance: textfield;
    width: 100%;
  }

  &__input__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__max-button {
    border-radius: 13px;
    background-color: rgba(140, 242, 159, 0.13);
    white-space: nowrap;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #8cf29f;
    letter-spacing: normal;
    line-height: 21px;
    padding: 4px 1rem 5px 1rem;
    cursor: pointer;
    font-weight: 500;
    margin: 0 1rem;
  }

  &__arrow-buttons {
    justify-content: space-around;
    margin: 1rem;
    height: 2.5rem;
  }

  &__up-down-button {
    padding: 0.2rem;
    margin: 0.2rem;
    height: 15px;
    width: 15px;
    border-radius: 4px;
    font-size: 17px;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &__image {
      height: 17.33px;
      width: 22px;
    }
  }

  &__text {
    color: #7d879c;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    font-weight: 400;
  }

  &__buttons {
    width: 100%;
    justify-content: space-around;
    margin-bottom: 1rem;

    button {
      width: 40%;
    }
  }
}

.react-datetime-picker {
  background: white;
  margin-bottom: 1rem;
  width: 100%;
}
