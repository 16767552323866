input {
font-size: 15px;
}

.farms-ai {
  max-width: 1200px;
  margin: 0 auto;
  // padding: 0 15px;
  min-height: 100vh;
  background: #1a2730;
  padding: 0rem 15px 4rem 15px;
    @include respond(phone) {
    padding: 7rem 0rem 1rem 0rem;
  }

  a {
    text-decoration: none;
  }

  &__menu {
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 27px 15px 15px 15px;
    // padding: 15px;

    @include respond(tab-port) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-left {
      width: 70%;
      margin-right: 3rem;

      @include respond(tab-port) {
        margin-right: 0rem;
        order: 2;
        width: 100%;
      }

      &__search-collectible {
        // margin-bottom: 1.5rem;
        justify-content: space-between;
        align-items: center;

        &__title {
          color: #ffffff;
          font-size: 27px;
          font-family: "poppins-bold";
          font-weight: 600;
          letter-spacing: 0;
          line-height: 40.5px;
        }
      }
    }
  }
}


.input-container.collapse .rounded-button-ai {
  right: 87.5%; /* Move button to the start */
}

.input-container {
   position: relative;
    display: flex;
    // height: 50px;
    border-radius: 25px;
    overflow: hidden;
    background: rgb(43, 55, 64);
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 2px; /* Add a small padding */
  }
  
  .rounded-input-ai {
    flex: 1;
    padding: 10px;
    border: none;
    // border-radius: 100px 0 0 100px;
    outline: none;
    color: white;
    margin-left: 7px;
    font-size: 15px;
    background: transparent;
  }

  .rounded-input-ai-collapsed {
    flex: 1;
    padding: 10px;
    border: none;
    height: 0;
    // border-radius: 100px 0 0 100px;
    outline: none;
    color: white;
    margin-left: 7px;
    font-size: 15px;
    background: transparent;
  }
  
  .rounded-button-ai {
    position: absolute;
    right: 3px; /* Button starts at the end */
    transition: right 0.5s ease; /* Smooth transition for moving the button */
    background: linear-gradient(90deg, #ff739f 0%, #F83472  100%) 0% 0% no-repeat padding-box; /* Gradient from purple to blue */
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 100px; /* Round both sides */
    cursor: pointer;
    font-size: 13px;
    display: flex;
    margin: 2px;
    align-items: center; /* Center icon vertically */
  }
  
  .rounded-button-ai .fas {
    margin-left: 5px; /* Add space between text and icon */
    font-size: 18px; /* Adjust icon size */
  }
  
  .rounded-button-ai:hover {
    background: linear-gradient(90deg, #F83472 0%, #f51a5f 100%) 0% 0% no-repeat padding-box; /* Gradient on hover */
  }


  .circle-button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    // gap: 10px;
  }
  
  .circle-button {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
    border-radius: 50%;
    background-color:rgb(101 101 101 / 40%);
    color: #ffffff99;
    //color: #000;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .circle-button.selected {
    //background: linear-gradient(90deg, #800080 0%, #0000FF 100%) 0% 0% no-repeat padding-box; /* Gradient from purple to blue */
    background: #F83472;
    color: #fff;
    border-radius: 50%; /* Make the selected button circular */
  }
  

  .toggle-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 10px;
    // margin: 10px;
  }
  
  .title {
    font-size: 18px;
    font-weight: bold;
    color: white;
  }
  
  .toggle-button {
    width: 50px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: background 0.3s;
  }
  
  .toggle-button .slider-toggle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: white;
    position: absolute;
    transition: transform 0.3s;
    margin-left: 3px;
  }
  
//   .toggle-button .active {
//     background: linear-gradient(90deg, #800080 0%, #0000FF 100%) 0% 0% no-repeat padding-box;
//   }
  
  .toggle-button.active .slider-toggle {
    transform: translateX(20px); /* Slide the toggle to the right when active */
  }

 
.slider-container {
    width: 300px;
    position: relative;
  }
  
  .slider {
    width: 100%;
    height: 10px;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border-radius: 5px;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-1%,-144%);
    z-index: 100;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #717171;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    z-index: 2; /* Ensure the thumb is above the gradient line */
  }
  
  .gradient-line {
    height: 6px;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 1; /* Place the gradient line behind the thumb */
  }
  
  .slider-thumb {
    width: 20px;
    height: 20px;
    background-color: #800080;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; /* Ensure the thumb is above the gradient line */
  }


  //#########################################

  .button-ratio.selected-ratio .circle {
    background: linear-gradient(to bottom, rgb(255 159 189), rgb(248, 52, 114));
  }
  
  .button-ratio.selected-ratio .circle .check-mark {
    display: block;
  }

  .button-ratio {
    display: flex;
    align-items: center;
    width: 50%;
    font-size: 14px;
    color: #ffffff69;
    // margin-right: 20px;
     margin-bottom: 10px;
    // padding: 10px;
    // border: 2px solid #000;
    // border-radius: 50px;
    cursor: pointer;
  }

  .button-ratio.selected-ratio {
    color: white
  }
  
  .circle {
    width: 20px;
    height: 20px;
    border: 1px solid #ffffff69;
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
  }
  
  .circle.checked {
    background: linear-gradient(to bottom, rgb(255 159 189), rgb(248, 52, 114));
    border: 0px solid #000;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }

  .circle.selected-r{
    border: 0px solid #000;
  }
  
  .check-mark {
    color: #fff;
    font-size: 18px;
  }
  
  .button-ratio.checked .circle {
    background: linear-gradient(to bottom,  rgb(255 159 189), rgb(248, 52, 114));
    display: flex;
    justify-content: center;
    align-items: center;
  }



.stack-container {
    position: relative; /* Container should have a relative position */
    width: 200px; /* Adjust the width as needed */
    height: 200px; /* Adjust the height as needed */
  }
  
  .stacked-div {
    position: absolute; /* Stack the divs absolutely within the container */
    width: 100%; /* Occupy full width of the container */
    height: 100%; /* Occupy full height of the container */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
  }


  .aspect-ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 50%; /* 1:2 Aspect Ratio (150% = 2 / 1) */
    overflow: hidden;
  }


  .info-section-ai {
    width: 30%;
    margin-top: 1.5rem;
    // overflow-y: scroll;
    @include respond(tab-port) {
      width: 100%;
      padding: 2rem;
    }
  
    @include respond(phone) {
      padding: 2rem;
      overflow-y: scroll;
      margin-top: 0.5rem;
    }
  
    &__detail {
      color: #FFF;
      margin: 3rem 0rem 2rem 0rem;
    }
  
    &__info-box-locked {
      padding: 2rem;
      height: 175px;
      width: 100%;
      margin-bottom: 1rem;
  
      @include respond(tab-port) {
        width: 100%;
      }
    }
  
    &__infoIcon {
      background-color: #4c565d;
      border-radius: 50%;
      width: 37px;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
    }
  
    &__info-box-rewards {
      padding: 2rem;
      height: 300px;
      width: 100%;
      margin: 1rem 0rem;
  
      @include respond(tab-port) {
        width: 100%;
      }
    }
  
    &__info-box-rewards-no-height {
      padding: 2rem;
      width: 100%;
      margin: 1rem 0rem;
  
      @include respond(tab-port) {
        width: 100%;
      }
    }
  
    &__info-box-user {
      padding: 2rem;
      height: 260px;
      width: 100%;
      margin: 1rem 0rem;
  
      @include respond(tab-port) {
        width: 100%;
      }
    }
  
    &__header {
      color: #b3acbb;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      &__icon {
        color: #b3acbb;
        letter-spacing: 0;
        line-height: 25px;
        height: 18px;
        width: 18px;
      }
    }
  
    &__pair {
      align-items: flex-end;
      margin-top: 1rem
    }
  
    &__number {
      color: #e1e2e6;
      color: #e1e2e6;
      font-weight: 500;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 35px;
      margin-right: 0.5rem;
    }
  
    &__balance {
      color: #e1e2e6;
      color: #e1e2e6;
      font-size: 20px;
      // font-family: 'DM Sans', system-ui, sans-serif;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 25px;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  
    &__currency {
      color: #e1e2e6;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 0.3rem;
    }
  
    &__account {
      color: #b3acbb;
      letter-spacing: 0;
      line-height: 21px;
      word-wrap: break-word;
    }
  }
  


  .zoomable-image {
    max-width: 100%;
    height: auto;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .zoomable-image.zoomed {
    transform: scale(2); /* Adjust the zoom level as needed */
  }

  .input-container.collapse {
    // height: 0px;
    transition: transform 0.3s ease-in-out;
    // padding: 0px;
  }


  // .zoomable-image {
  //   max-width: 100%;
  //   height: auto;
  //   cursor: pointer;
  //   transition: transform 0.3s ease-in-out;
  // }
  
  // .zoomable-image.zoomed {
  //   transform: scale(2); /* Adjust the zoom level as needed */
  // }
  
  // .zoomed-image {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-size: 200% 200%; /* Adjust the background size as needed */
  //   z-index: 999;
  //   pointer-events: none;
  // }

  .item-checkbox{
    position: relative;
    left: 60px;
    height: 30%;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    color: white;
    opacity: .0;
  }

  .item-checkbox.visible{
    opacity: 1;
  }



  .button-container {
  display: block;
  border: 1.5px solid #F83472;
  border-radius: 10px;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.custom-button {
  height: 50px;
  // padding: 10px 20px;
  width: 100%;
  border-radius: 8px;
  margin-left: 0px;
  white-space: nowrap;
  text-align: center;
    display: block;
  background-color: transparent;
  color: #F83472;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button.loading {
  background-color: #F83472;
  color: #fff;
  animation: fill 5s linear forwards;
}

@keyframes fill {
  0% {
    width: 0;
  }
  100% {
    width: 80%;
    // width: 30%;
  }
}