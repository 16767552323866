@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
  }
}
.loader {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(26, 39, 48, 0.8);
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loadContainer {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loadLogo {
  width: 180px;
}

.loadText {
  display: flex;
  color: white;
  font-size: 30px;
  font-weight: 600;
  white-space: nowrap;
}

.animText span {
    padding-left: 7px;
  display: inline-block;
  animation: waviy 1s infinite;
  animation-delay: calc(0.2s * var(--i));
}
