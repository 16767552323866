.nft-buy-launchpad-modal {
  position: fixed; /* Stay in place */
  z-index: 10000000000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  background: rgba(26, 39, 48, 0.8);
  backdrop-filter: blur(19.028px);
}

.close-icon-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.close-icon {
  width: 3rem;
  height: 3rem;
  margin: 3rem;
  cursor: pointer;
}

.middle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95%;
  @include respond(phone) {
    height: auto;
  }
}

.item-container {
  display: flex;
  flex-direction: column;
  width: 14rem;
  margin: 1.5rem;
  margin-left: 8rem;
  align-self: flex-start;
  @include respond(phone) {
    display: none;
  }
}

.item-text {
  font-family: poppins-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #a3a8ac;
}

.item-image-container {
  img {
    width: 14rem;
    height: 15rem;
  }
}

.product-icon {
  width: 150px;
  position: absolute;
  left: -55px;
  top: -40px;
}

.img-container {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2b3740;
  margin-top: 2rem;
}

.nft-buy-launchpad-content {
  display: flex;
  justify-content: center;
  padding: 3rem 10rem;
  height: calc(100% - 10rem);
  @include respond(phone) {
    padding: 0 2rem;
  }
}
