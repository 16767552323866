.transactions-modal {
  height: 343px;
  width: 690px;
  max-width: 80%;
  border-radius: 10px;
  background-color: #2b2c3e;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  @include respond(tab-port) {
    width: 95%;
    max-width: 95%;
  }

  &__title {
    height: 51px;
    width: 224px;
    color: #e1e2e6;
    font-weight: 500;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 55px;
    margin: 2rem;
  }

  &__steps {
    &__single-step {
      display: flex;
      align-items: center;
      height: 55px;
      width: 180px;
      border-radius: 8px;
      color: #f7f7f7;
      font-family: Poppins;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
      margin: 0 1rem 0 2rem;

      &__number {
        box-sizing: border-box;
        height: 35px;
        width: 35px;
        border: 1px solid rgba(247, 247, 247, 0.5);
        background-color: rgba(216, 216, 216, 0);
        border-radius: 50%;
        color: #f7f7f7;
        margin: 0 1rem;

        font-size: 16px;
        letter-spacing: 0;
        line-height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__current-step {
    height: 200px;
    width: 450px;
    border-radius: 10px;
    background-color: #1e1d2b;
    display: flex;
    justify-content: space-evenly;
    padding-left: 2rem;

    @include respond(phone) {
      width: 250px;
    }

    &__title {
      height: 33px;
      width: 255px;
      color: #e1e2e6;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 35px;
    }

    &__text {
      height: 20px;
      width: 332px;
      color: #e1e2e6;
      font-family: Poppins;
      font-weight: 200;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
    }

    &__button {
      width: 40%;
      font-weight: 400;
    }
  }
}
