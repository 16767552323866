.Dropdown-placeholder {
  display: none;
}
.netselector-text {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1024px) {
    justify-content: center;
  }
}
.network-selector {
  @media (max-width: 1024px) {
    width: 90%;
    margin-bottom: 2rem;
    position: fixed;
    bottom: 5rem;
  }
}

.MuiBox-root {
  .MuiInputBase-root {
    color: white;

    .MuiSelect-icon {
      color: white;
    }
    .MuiSelect-outlined {
      color: white;
      border: 1.2px solid rgba(255, 255, 255, 0.4);
    }
  }
}
.MuiPopover-root {
  .MuiPaper-root {
    background-color: #1a2730;

    .MuiList-root {
      background-color: #1a2730;
      color: white;
      border-radius: 7px;

      .MuiButtonBase-root {
        font-size: 15px;
        border-radius: 10px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .Mui-selected {
        background-color: rgba(225, 218, 210, 0.18);
      }
    }
  }
}
