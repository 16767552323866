.wallet-button {
  cursor: pointer;
  // width: 200px;
  // height: 200px;
  border-radius: 6px;
  font-family: poppins-regular;
  font-size: 15px;

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  // &:active {
  //   transform: translateY(0.1rem);
  //   background:#f83472;
  // }

  &:hover {
    background:#FFFFFF33;
  }


}

.selected{
  background: #FFFFFF33;
  border-radius: 6px;
}

