.my-orders {
  align-items: center;
  background: #1a2730;
  padding: 8rem 2rem 4rem 2rem;
  min-height: 100vh;
  @include respond(phone) {
    align-items: none;
  }
}

.nft-heading {
  margin: 2rem 0;
  font-size: 27px;
}

.emptyPageContainer {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.emptyImg {
  width: 150px;
}

.noOrder {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.emptyText {
  display: flex;
  font-size: 18px;
  margin-top: 10px;
  text-align: center;
  color: #a3a8ac;
}

.orders {
  width: 80%;
  min-height: 100vh;
  background: #1a2730;
  padding-bottom: 4rem;
  @include respond(phone) {
    padding: 0rem;
    width: 100%;
  }
}

.orders-menu {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.sort-menu {
  justify-content: space-between;
  font-size: 16px;
  color: #a3a8ac;
  &__dropdown {
    padding-left: 24px;
  }
}