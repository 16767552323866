.shipping-label {
  font-family: "poppins-regular";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #a3a8ac;
}

.shipping-input {
  background: #1a2730;
  border: 1px solid #54585b;
  border-radius: 7px;
  margin: 1rem 0;
  width: 100%;
  &__address {
    background: #1a2730;
    border: 1px solid #54585b;
    border-radius: 7px;
    margin: 1rem 0;
    width: 100%;
    height: 94px;
  }
}

.section {
  margin-bottom: 2rem;
}

.email-phone{
  display: flex;
  flex-direction: row;
  @include respond(phone) {
    flex-direction: column;
  }
}

.phone-container {
  width: 100%;
  margin-left: 2rem;
  @include respond(phone) {
    margin-left: 0;
  }
}
.phone-input {
  width: 100%;
  background-color: transparent;
}