h1 {
  font-size: 27px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

h2 {
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

h3 {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

p,
span button,
input,
div {
  font-family: "poppins-regular", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "poppins-bold", sans-serif;
}
