.customModal {
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: 100vh;
    background-color: rgba(26, 39, 48, 0.8);
    backdrop-filter: blur(7px);

    
    @include respond(phone) {
      height: 100%;
      padding: 0px;
    }
  }