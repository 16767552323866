.rais-slider,
.sold-slider {
  -webkit-appearance: none;
  width: 100%;
  border-radius: 5px;
  background-color: #1c1532;
  outline: none;
  border-radius: 7px;
  border: solid 1px rgba(255, 255, 255, 0.42);
  height: 15px;
}

.rais-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  border: 0;

  padding: 0;
  position: relative;
}

.rais-slider::-moz-range-thumb {
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
}
