.expanded-nft-item {
  background-color: #243b6d;
  padding: 1rem 1rem 1rem 3rem;

  &__rewards {
    width: 30%;
    justify-content: space-between;
    margin-right: 3rem;

    &__text-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }

  &__info-area {
    border-radius: 7px;
    background: #4c565d;
    @include respond(phone) {
      background: transparent;
      padding: 0px;
    }
  }

  &__edition-img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 11px;
  }

  &__edition-text {
    color: #a3a8ac;
    font-size: 13px;
  }

  &__staking {
    width: 70%;
    justify-content: space-around;

    @include respond(medium-desktop) {
      justify-content: space-between;
    }

    &__button {
      padding: 0.8rem 2.5rem;

      @include respond(laptop) {
        padding: 0.8rem 2.3rem;
      }
      @include respond(tab-land) {
        padding: 0.6rem 2rem;
      }
    }

    &__text {
      width: 30%;
      justify-content: space-between;
      margin-right: 1rem;
      color: #e1e2e6;
    }

    &__buttons {
      width: 70%;
      justify-content: space-between;
    }

    &__text-button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }

  &__title {
    color: rgba(225, 226, 230, 0.6);
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 1rem;
  }

  &__text {
    color: #e1e2e6;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 21px;
  }

  &__link {
    color: #3986eb;
    font-weight: 300;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;

    &:first-child {
      margin-right: 6rem;
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  margin: 26px 1.3px 19px 0;
  opacity: 0.6;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.15);
}
