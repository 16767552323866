.box-small {
}

.box-medium {
  background: #2B3740;
  box-shadow: 0px 16px 57px rgba(0, 0, 0, 0.196924);
  border-radius: 4px;
  
  @include respond(phone) {
    background: transparent;
    box-shadow: none;
  }
}

.box-big {
  padding: 23px;
  
  @include respond(phone) {
    padding: 0;
  }
}
